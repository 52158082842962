/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { compose } from 'recompose';
import styledSystem from '@emotion/styled';
import { toast } from 'react-toastify';
import { graphql, navigate } from 'gatsby';
import LayoutWithAuth from '../components/layoutWithAuth';
import { withAuthorization, withEmailVerification } from '../components/Session';
import { Registrations } from '../components/Registrations';
import { Box, Flex } from '../style/basicStyle';

const RegistrationsPageBase = (props) => {
  return (
    <Box px={[2, 3, 5]}>
      <h3>Registrations</h3>
      <Registrations />
    </Box>
  );
};

const condition = (authUser) => authUser;

const RegistrationsPage = compose(withEmailVerification, withAuthorization(condition))(RegistrationsPageBase);

const Named = ({ toggleNavDark, location }) => {
  return (
    <Box py={[5]}>
      <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
        <RegistrationsPage />
      </LayoutWithAuth>
    </Box>
  );
};

export default Named;

export const pageQuery = graphql`
  query registrationsContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "registrations" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
